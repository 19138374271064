







import BaseStringList from '@/views/board-settings/Actions/ConditionViews/components/BaseStringList.vue'
import InvertCondition from '@/views/board-settings/Actions/ConditionViews/components/InvertCondition.vue'
import { WordEqualsCondition } from '@/includes/types/PmConfig.types'

import { Component, VModel } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { BaseStringList, InvertCondition }
})
export default class WordEquals extends Vue {
  @VModel() model!: WordEqualsCondition
}
